




















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'rs-tab',
})
export default class RSTab extends Vue {
  @Prop({ required: false, default: false }) active?: boolean;

  @Prop({ required: false }) to?: string;
}
