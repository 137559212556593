










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'rs-tabs',
})
export default class RSTabs extends Vue {
  @Prop({ required: false }) type?: string;
}
