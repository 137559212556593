

































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsTabs from '@/shared/components/tabs.vue';
import RsTab from '@/shared/components/tab.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Icons} from '@/shared/components/icon/icons';
import RsButton from '@/shared/components/buttons/button.vue';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';
import RsApplicationCard from '@/modules/dashboard/modules/applications/components/card.vue';
import {API_SERVICE} from '@/shared/api/api.service';
import {
  IApiJobApplication,
  IApiJobApplicationOverview,
  JobStageName
} from '@/shared/models/IApiJob';
import {CodetableResult, Codetables} from '@/shared/api/modules/codetable.api.service';
import {JobsRouter} from '@/modules/dashboard/modules/jobs/router';

export enum StageCategories {
  PENDING = 'pending',
  HIRING = 'hiring',
  OFFER = 'offer',
  HIRED = 'hired',
  ARCHIVED = 'archived'
}

@Component({
  components: {
    RsApplicationCard,
    RsLinkIcon,
    RsButton,
    RsIcon,
    RsTab,
    RsTabs
  }
})
export default class ApplicationsIndex extends Vue {

  StageCategories = StageCategories;

  JobsRouter = JobsRouter;

  jobStageCategories: Record<JobStageName, StageCategories> = {
    [JobStageName.VETTED]: StageCategories.PENDING,
    [JobStageName.APPLIED]: StageCategories.PENDING,
    [JobStageName.QUIZ]: StageCategories.HIRING,
    [JobStageName.TECHNICAL_VETTING]: StageCategories.HIRING,
    [JobStageName.ON_HOLD]: StageCategories.HIRING,
    [JobStageName.READY_FOR_CLIENT]: StageCategories.HIRING,
    [JobStageName.PRESENT_TO_CLIENT]: StageCategories.HIRING,
    [JobStageName.OFFER]: StageCategories.OFFER,
    [JobStageName.HIRED]: StageCategories.HIRED,
    [JobStageName.ARCHIVED]: StageCategories.ARCHIVED,
  }

  Icons = Icons

  selectedStage: '' | StageCategories = ''

  applications: IApiJobApplication[] = [];
  overview: IApiJobApplicationOverview[] = [];

  categoryCount: Record<StageCategories, number> = {
    [StageCategories.HIRING]: 0,
    [StageCategories.PENDING]: 0,
    [StageCategories.ARCHIVED]: 0,
    [StageCategories.OFFER]: 0,
    [StageCategories.HIRED]: 0,
  }

  codetables: CodetableResult = {};

  get withdrawReasons() {
    return this.codetables?.job_application_archive_reasons?.filter((r) => r.userType === 'talent') || [];
  }

  filterApplicationByCategory(category: StageCategories) {
    return this.applications.filter((a) => {
      if (this.jobStageCategories[a.stageName as JobStageName]) {
        return this.jobStageCategories[a.stageName as JobStageName] === category;
      }
      return category === StageCategories.PENDING
    })
  }

  filterApplicationByArchiveType(applications: IApiJobApplication[], withdraw: boolean) {
    return applications.filter((a) => {
      const isWithdraw = this.withdrawReasons.map((r) => r.name).includes(a.archiveReason || '');
      return withdraw ? isWithdraw : !isWithdraw;
    })
  }

  getCategoryCount() {
    this.categoryCount = this.overview.map((o) => ({
      ...o,
      category: this.jobStageCategories[o.name] ? this.jobStageCategories[o.name] : StageCategories.PENDING
    })).reduce((a, b) => {
      a[b.category] += b.count
      return a;
    }, {
      [StageCategories.HIRING]: 0,
      [StageCategories.PENDING]: 0,
      [StageCategories.ARCHIVED]: 0,
      [StageCategories.OFFER]: 0,
      [StageCategories.HIRED]: 0,
    })
  }

  getApplications() {
    API_SERVICE.jobs.getApplications()
      .then((res) => {
        this.applications = res;
      })
  }

  getOverview() {
    API_SERVICE.jobs.getApplicationOverview()
      .then((res) => {
        this.overview = res;
        this.getCategoryCount();
      })
  }

  getData() {
    this.getOverview();
    this.getApplications();
  }

  mounted() {
    this.getData();
    API_SERVICE.codetable.getCodetables(Codetables.JOB_APPLICATION_ARCHIVE_REASONS)
      .then((res) => {
        this.codetables = res;
      })
  }
}
