










































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsTabs from '@/shared/components/tabs.vue';
import RsTab from '@/shared/components/tab.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Icons} from '@/shared/components/icon/icons';
import RsButton from '@/shared/components/buttons/button.vue';
import RsLinkIcon from '@/shared/components/link/link-icon.vue';
import {Prop} from 'vue-property-decorator';
import {IApiJobApplication, JobInterviewType, JobStageName} from '@/shared/models/IApiJob';
import {JobsRouter} from '@/modules/dashboard/modules/jobs/router';
import RsDropdown from '@/shared/components/dropdown.vue';
import {API_SERVICE} from '@/shared/api/api.service';
import {
  JobApplicationArchiveReasons
} from '@/shared/api/modules/codetable.api.service';
import {DATE_SERVICE} from '@/shared/services/date.service';

@Component({
  name: 'rs-application-card',
  components: {
    RsDropdown,
    RsLinkIcon,
    RsButton,
    RsIcon,
    RsTab,
    RsTabs
  }
})
export default class ApplicationsCard extends Vue {
  @Prop({required: true}) application: IApiJobApplication
  @Prop({required: true}) reasons: JobApplicationArchiveReasons[]
  @Prop({required: false}) archived: boolean
  @Prop({required: false}) disableActions: boolean

  Icons = Icons

  JobsRouter = JobsRouter;

  d = DATE_SERVICE.instance;

  withdrawing = false;

  get stageNames(): Record<JobStageName | JobInterviewType, string>{
    return {
      [JobStageName.VETTED]: 'Applied',
      [JobStageName.APPLIED]: 'Applied',
      [JobStageName.QUIZ]: 'Remotesome Vetting Process',
      [JobStageName.TECHNICAL_VETTING]: 'Remotesome Vetting Process',
      [JobStageName.ON_HOLD]: 'Remotesome Vetting Process',
      [JobStageName.READY_FOR_CLIENT]: 'Remotesome Vetting Process',
      [JobStageName.PRESENT_TO_CLIENT]: 'Remotesome Vetting Process',
      [JobStageName.OFFER]: 'Offer',
      [JobStageName.HIRED]: 'Hired',
      [JobStageName.ARCHIVED]: 'Rejected',
      [JobInterviewType.INTRO_INTERVIEW]: 'Intro Interview',
      [JobInterviewType.SCREENING_INTERVIEW]: 'Screening Interview',
      [JobInterviewType.CULTURE_FIT_INTERVIEW]: 'Culture Fit Interview',
      [JobInterviewType.HR_INTERVIEW]: 'HR Interview',
      [JobInterviewType.TECHNICAL_INTERVIEW]: 'Technical Interview',
      [JobInterviewType.PAIR_PROGRAMMING_INTERVIEW]: 'Pair Programming Interview',
      [JobInterviewType.SYSTEM_DESIGN_INTERVIEW]: 'System Design Interview',
      [JobInterviewType.CODING_INTERVIEW]: 'Coding Interview',
      [JobInterviewType.ONSITE_INTERVIEW]: 'Onsite Interview',
      [JobInterviewType.FINAL_INTERVIEW]: 'Final Interview',
      [JobInterviewType.LEADERSHIP_INTERVIEW]: 'Leadership Interview',
      [JobInterviewType.TAKE_HOME_CODING]: 'Take Home Coding',
    }
  }

  get withdrawnReasons(){
    return this.reasons?.filter((r) => r.userType === 'talent') || [];
  }

  get isWithdraw(){
    return this.withdrawnReasons.map((r) => r.name).includes(this.application.archiveReason || '');
  }

  get applicationReason(){
    return this.reasons.find((r) => r.name === this.application.archiveReason);
  }

  formatSalary(salary: number){
    if(salary % 1000 > 0){
      return `${Math.floor(salary/1000)}.${Math.floor(salary%1000/100)}`
    }
    return Math.floor(salary/1000);
  }

  withdraw(reason: string){
    this.withdrawing = true;
    API_SERVICE.jobs.unapplyForJob(this.application.jobId, reason)
      .then(() => {
        this.$emit('reload');
      })
      .finally(() => {
        this.withdrawing = false;
      })

  }
}
