









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'rs-dropdown',
})
export default class RSDropdown extends Vue {
  @Prop({ required: false }) type?: string;

  @Prop({ required: false }) disabled?: boolean;

  active = false;

  toggle() {
    this.active = !this.active;
    this.$emit('open', this.active);
  }

  close() {
    this.active = false;
    this.$emit('open', false);
  }
}
