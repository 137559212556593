import {UUID} from '@/shared/constants/UUID';
import {IApiJobData} from '@/shared/models/IApiJobData';
import {IApiCompany} from '@/shared/models/IApiCompany';
import {JOB_STATE} from '@/shared/constants/JOB_STATE.ts';
import {IApiCompanyProperties} from '@/shared/models/IApiCompanyData';

export interface IApiJob {
  id: UUID;
  data: IApiJobData;
  dataVersion: number;
  state: JOB_STATE;
  featured: boolean;
  sortOrder: number;
  createdAt: string;
  company: IApiCompany;
  applied?: boolean;
}

export interface IApiJobApplicationOverview{
  name: JobStageName;
  count: number;
}

export interface IApiJobApplication {
  applicationChangedAt: string;
  applicationCreatedAt: string;
  archiveReason: string | null;
  companyProperties: IApiCompanyProperties
  jobData: IApiJobData
  jobId: string;
  stageName: JobStageName | JobInterviewType;
}

export enum JobStageName {
  VETTED = 'VETTED',
  APPLIED = 'APPLIED',
  QUIZ = 'QUIZ',
  TECHNICAL_VETTING = 'TECHNICAL_VETTING',
  ON_HOLD = 'ON_HOLD',
  READY_FOR_CLIENT = 'READY_FOR_CLIENT',
  PRESENT_TO_CLIENT = 'PRESENT_TO_CLIENT',
  OFFER = 'OFFER',
  HIRED = 'HIRED',
  ARCHIVED = 'ARCHIVED',
}

export enum JobInterviewType {
  INTRO_INTERVIEW = 'INTRO_INTERVIEW',
  SCREENING_INTERVIEW = 'SCREENING_INTERVIEW',
  CULTURE_FIT_INTERVIEW = 'CULTURE_FIT_INTERVIEW',
  HR_INTERVIEW = 'HR_INTERVIEW',
  TECHNICAL_INTERVIEW = 'TECHNICAL_INTERVIEW',
  PAIR_PROGRAMMING_INTERVIEW = 'PAIR_PROGRAMMING_INTERVIEW',
  SYSTEM_DESIGN_INTERVIEW = 'SYSTEM_DESIGN_INTERVIEW',
  CODING_INTERVIEW = 'CODING_INTERVIEW',
  ONSITE_INTERVIEW = 'ONSITE_INTERVIEW',
  FINAL_INTERVIEW = 'FINAL_INTERVIEW',
  LEADERSHIP_INTERVIEW = 'LEADERSHIP_INTERVIEW',
  TAKE_HOME_CODING = 'TAKE_HOME_CODING',
}


